import { APIPaths } from "./paths";
import { withAuthTokenHeader } from "./utils";

export const getAllUserCards = async () => {
    const res = {};
    try {
        const returned = await withAuthTokenHeader().get(APIPaths.CARDS);
        res.data = returned.data;
    } catch (e) {
        res.error = e;
    }
    return res;
};

export const getUserCardDetails = async (id) => {
    let res;
    try {
        const returned = await withAuthTokenHeader().get(
            `${APIPaths.CARDS}/${id}`,
        );
        res.data = returned.data;
    } catch (e) {
        res.error = e;
    }
    return res;
};
