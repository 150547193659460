import * as React from "react";
import Box from "@mui/joy/Box";
import { Autocomplete } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Avatar from "@mui/joy/Avatar";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import ListDivider from "@mui/joy/ListDivider";
import { useNavigate } from "react-router-dom";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import Logo from "../../images/FINIMMERSE-A.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { useContext, useEffect } from "react";
import { AuthState } from "../../state/auth.context";
import { APIPaths } from "../../api/paths";

export default function Header() {
    const [searchData, setSearchData] = React.useState([]);
    const { logoutUserAccount, useLogout } = useContext(AuthState);
    const { submittingLogout, logoutSucceeds, logoutFails, beginLogout } =
        useLogout();
    const navigate = useNavigate();

    function handleSearchInput(e) {
        axios
            .get(APIPaths.SEARCH)
            .then((resp) => {
                setSearchData(resp.data);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    useEffect(() => {
        if (logoutSucceeds === true) {
            logoutUserAccount();
        }
    }, [logoutSucceeds]);

    return (
        <Box
            sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "space-between",
            }}
        >
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: { xs: "none", sm: "flex" },
                }}
            >
                <IconButton
                    component={Link}
                    to="/app"
                    size="md"
                    variant="outlined"
                    color="neutral"
                    sx={{
                        display: { xs: "none", sm: "inline-flex" },
                        borderRadius: "50%",
                    }}
                >
                    <img src={Logo} height={20} alt="Finimmerse Logo" />
                </IconButton>
                <Typography>Fantasy Stocks</Typography>
            </Stack>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1.5,
                    alignItems: "center",
                }}
            >
                <Autocomplete
                    placeholder="Type anything"
                    options={searchData.map((opt) => ({
                        label: `${opt.type}: ${opt.label}`,
                        value: `/app/cards/${opt.value}`,
                    }))}
                    onInputChange={(event, value) => handleSearchInput(value)}
                    onBlur={() => setSearchData([])}
                    onChange={(e, value) => {
                        if (value?.value) navigate(value?.value);
                    }}
                    sx={{
                        width: "400px",
                    }}
                />
                <IconButton
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    sx={{
                        display: { xs: "inline-flex", sm: "none" },
                        alignSelf: "center",
                    }}
                >
                    <SearchRoundedIcon />
                </IconButton>
                <Dropdown>
                    <MenuButton
                        variant="plain"
                        size="sm"
                        sx={{
                            maxWidth: "32px",
                            maxHeight: "32px",
                            borderRadius: "9999999px",
                        }}
                    >
                        <Avatar
                            src="https://i.pravatar.cc/40?img=2"
                            srcSet="https://i.pravatar.cc/80?img=2"
                            sx={{ maxWidth: "32px", maxHeight: "32px" }}
                        />
                    </MenuButton>
                    <Menu
                        placement="bottom-end"
                        size="sm"
                        sx={{
                            zIndex: "99999",
                            p: 1,
                            gap: 1,
                            "--ListItem-radius": "var(--joy-radius-sm)",
                        }}
                    >
                        <MenuItem>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Avatar
                                    src="https://i.pravatar.cc/40?img=2"
                                    srcSet="https://i.pravatar.cc/80?img=2"
                                    sx={{ borderRadius: "50%" }}
                                />
                                <Box sx={{ ml: 1.5 }}>
                                    <Typography
                                        level="title-sm"
                                        textColor="text.primary"
                                    >
                                        Adam Alesandro
                                    </Typography>
                                    <Typography
                                        level="body-xs"
                                        textColor="text.tertiary"
                                    >
                                        adam@finimmerse.com
                                    </Typography>
                                </Box>
                            </Box>
                        </MenuItem>
                        <ListDivider />
                        <MenuItem>
                            <HelpRoundedIcon />
                            Help
                        </MenuItem>
                        <MenuItem>
                            <SettingsRoundedIcon />
                            Settings
                        </MenuItem>
                        <ListDivider />
                        <MenuItem
                            onClick={() => {
                                beginLogout();
                            }}
                        >
                            <LogoutRoundedIcon />
                            Log out
                        </MenuItem>
                    </Menu>
                </Dropdown>
                <Box>
                    {submittingLogout && (
                        <Typography>logging out...</Typography>
                    )}
                    {logoutSucceeds && <Typography>logout complete</Typography>}
                    {logoutFails && (
                        <Typography>logout failed, please try again</Typography>
                    )}
                </Box>
            </Box>
        </Box>
    );
}
