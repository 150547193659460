import { Outlet, useLocation, useParams } from "react-router";
import { Box, Breadcrumbs, Typography } from "@mui/joy";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { Link } from "react-router-dom";
import qs from "qs";
import { useState, useEffect } from "react";
import { tournamentById } from "../api/tournaments.service";
import TournamentAccessComponent from "./tournamentComponents/TournamentAccessComponent";
import ISODateStringFromatter from "./utilComponents/ISODateStringFormatter";
import { localizations } from "../utils/timeStampFormatTools";

export default function TournamentDetail() {
    const { id } = useParams();
    const [tournament, setTournament] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const location = useLocation();
    const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
    //need this so we know whether to render the button to enter the tournament
    //registration pipeline or a child component that's needed when the user
    //is already registering
    const userInRegistrationProcess = queryString.userRegistering
        ? true
        : false;

    useEffect(() => {
        tournamentById(id)
            .then((res) => {
                if (res.error) {
                    setError(true);
                } else if (res.data) {
                    setTournament(res.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    return (
        <>
            <Breadcrumbs
                separator={<KeyboardArrowLeft />}
                aria-label="breadcrumbs"
            >
                <Link to="/app">Dashboard</Link>
                <Link to="/app/tournaments">Tournaments</Link>
                <Typography>{id}</Typography>
            </Breadcrumbs>
            <>
                {loading === true ? (
                    "loading"
                ) : error === true ? (
                    "error"
                ) : (
                    <>
                        <TournamentDescription tournament={tournament} />
                        {!userInRegistrationProcess && (
                            <TournamentAccessComponent
                                tournament={tournament}
                            />
                        )}
                        {userInRegistrationProcess && <Outlet />}
                    </>
                )}
            </>
        </>
    );
}

function TournamentDescription({ tournament }) {
    const {
        tournamentName,
        tournamentDuration,
        tournamentStart,
        tournamentEnd,
        experienceLevel,
        numberOfCards,
    } = tournament;

    return (
        <Box sx={{ "& h2,h3": { margin: 0 } }}>
            <Box sx={{ marginBottom: "1em" }}>
                <h2>
                    <b>{tournamentName}</b>
                </h2>
                <h3>
                    <b style={{ whiteSpace: "nowrap" }}>
                        {tournamentDuration} Tournament, from{" "}
                        <ISODateStringFromatter
                            isoDateString={tournamentStart}
                            timelocalization={localizations.US}
                        />{" "}
                        to{" "}
                        <ISODateStringFromatter
                            isoDateString={tournamentEnd}
                            timelocalization={localizations.US}
                        />
                    </b>
                </h3>
            </Box>
            <Box>
                <h3>
                    <b>Experience Level: {experienceLevel}</b>
                </h3>
                <h3>
                    <b>Number of Cards: {numberOfCards}</b>
                </h3>
            </Box>
        </Box>
    );
}
