import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UIRoutePaths } from "../../router/fantasy-stocks-router";
import { getUserRegistrationStateForTournament } from "../../api/tournaments.service";
import { Button, Typography } from "@mui/joy";
import PlayerInTournamentComponent from "./PlayerInTournament/PlayerInTournamentComponent";

export default function TournamentAccessComponent({ tournament }) {
    const { id, numberOfCards } = tournament;
    const [cards, setCards] = useState(null);
    const [loadingPlayingStatus, setLoadingPlayingStatus] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        getUserRegistrationStateForTournament(id)
            .then((res) => {
                const isPlaying =
                    res.data.cards && res.data.cards.length ? true : false;
                if (isPlaying) {
                    setCards(res.data.cards);
                } else if (res.error) {
                    setError(true);
                }
            })
            .finally(() => setLoadingPlayingStatus(false));
    }, [id]);

    return loadingPlayingStatus === true ? (
        "fetching player entry-status"
    ) : error === true ? (
        "error"
    ) : (
        <>
            {!cards && (
                <>
                    <Typography>You are not playing</Typography>
                    <Button
                        variant="plain"
                        color="neutral"
                        component="a"
                        size="sm"
                        sx={{
                            flexDirection: "column",
                            "--Button-gap": 0,
                            backgroundColor: "red",
                        }}
                    >
                        <Link
                            to={`${UIRoutePaths.TOURNAMENTS}/${id}/cards-entry/num-cards/${numberOfCards}?userRegistering=true`}
                        >
                            Enter Tournament
                        </Link>
                    </Button>
                </>
            )}

            {cards && (
                <>
                    {/*<Button
                        variant="plain"
                        color="neutral"
                        component="a"
                        size="sm"
                        sx={{
                            flexDirection: "column",
                            "--Button-gap": 0,
                            backgroundColor: "red",
                        }}
                        onClick={() => {
                            localStorage.removeItem(`tournament_${id}`);
                            setUserPlaying(false);
                        }}
                    >
                        Unregister From Tournament
                    </Button>*/}
                    <PlayerInTournamentComponent
                        cards={cards}
                        tournament={tournament}
                    />
                </>
            )}
        </>
    );
}
