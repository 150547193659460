import { APIPaths } from "./paths";
import axios from "axios";

export const pulseCheck = async () => {
    const res = {};
    try {
        const fetched = await axios.get(APIPaths.PULSE_CHECK);
        res.data = fetched.data;
    } catch (e) {
        console.error("error establishing api connection", e);
        res.error = e;
    }
    return res;
};
