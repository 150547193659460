import { useState, createContext, useEffect } from "react";
import { loginUser /*logoutUser*/ } from "../api/auth.service";

import { TOKEN_IN_LOCAL_STORAGE_LABEL } from "../globalConsts.constants";

//decodes a jwt to be useable, returns currently accepted mock account for now
const parseJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(""),
    );

    return JSON.parse(jsonPayload);
};
//this is the user's account that gets set after the user successfully logs in and
//persisted in local-storage
export const useAccountData = () => {
    const [accountData, setAccountData] = useState({
        data: localStorage.getItem(TOKEN_IN_LOCAL_STORAGE_LABEL)
            ? parseJwt(localStorage.getItem(TOKEN_IN_LOCAL_STORAGE_LABEL))
            : null,
        setAfterLogin: (token) => {
            setAccountData({ ...accountData, data: parseJwt(token) });
            localStorage.setItem(TOKEN_IN_LOCAL_STORAGE_LABEL, token);
        },
        clearAfterLogout: () => {
            setAccountData({ ...accountData, data: null });
            localStorage.removeItem(TOKEN_IN_LOCAL_STORAGE_LABEL);
        },
    });

    return accountData;
};

export const useLogin = () => {
    const [loginState, setLoginState] = useState({
        accountLoginCorrect: false,
        accountLoginIncorrect: false,
        loginAttemptResponse: null,
        handleAccountLogin: async (
            userSubmission /*{email: string, password: string} */,
        ) => {
            const authResponse = await loginUser(
                userSubmission.email,
                userSubmission.password,
            );
            const loginSucceeds = authResponse.status === 200;
            const loginFails = !loginSucceeds;
            setLoginState({
                ...loginState,
                accountLoginCorrect: loginSucceeds,
                accountLoginIncorrect: loginFails,
                loginAttemptResponse: authResponse,
            });
        },
    });

    return loginState;
};

//todo-use a similar pattern for useLogin
export const useLogout = () => {
    const [logoutState, setLogoutState] = useState({
        submittingLogout: false,
        logoutSucceeds: false,
        logoutFails: false,
        beginLogout: () => {
            setLogoutState({
                ...logoutState,
                submittingLogout: true,
            });
        },
    });

    //until we get this implemented on the backend
    const logoutUser = async () => ({ status: 200 });
	
    useEffect(() => {
        if (logoutState.submittingLogout === true) {
            logoutUser().then((res) => {
                setLogoutState({
                    ...logoutState,
                    submittingLogout: false,
                    logoutSucceeds: res.status === 200,
                    logoutFails: res.status !== 200,
                });
            });
        }
    }, [logoutState.submittingLogout]);

    return logoutState;
};

export const AuthState = createContext({
    userAccount: null,
    useLogin: null,
    useLogout: null,
    logoutUserAccount: () => null,
});

export const AuthStateProvider = ({ children }) => {
    const userAccountDataState = useAccountData();

    const logoutUserAccount = () => {
        userAccountDataState.clearAfterLogout();
    };

    return (
        <AuthState.Provider
            value={{
                userAccount: userAccountDataState,
                useLogin,
                useLogout,
                logoutUserAccount,
            }}
        >
            {children}
        </AuthState.Provider>
    );
};
