import { Box, Grid, Typography } from "@mui/joy";
import { useState } from "react";
import NumberFormatter from "../../../utilComponents/NumberFormatter";

export default function CardDeck({ cardDeckState }) {
    const { returnsList, updating, error } = cardDeckState;
    return (
        <Box>
            <h4 style={{ margin: 0 }}>
                <b>My Portfolio's Return</b>
            </h4>
            {updating && <Typography>updating returns...</Typography>}
            {error && <Typography>error occured</Typography>}
            {returnsList.length > 0 && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        columnGap: "1em",
                    }}
                >
                    <Aggregate returnsList={returnsList} />
                    <ReturnsGrid returnsList={returnsList} />
                </Box>
            )}
        </Box>
    );
}

function Aggregate({ returnsList }) {
    //const [expanded, setExpanded] = useState(true);
    const returnTotal = returnsList.reduce((sum, returnObj) => {
        sum += returnObj.return;
        return sum;
    }, 0);

    return (
        <Box>
            <Typography>
                <b>
                    Total Return:{" "}
                    <NumberFormatter
                        value={returnTotal}
                        decimalPlaces={2}
                        makePercent={true}
                    />
                </b>
            </Typography>
        </Box>
    );
}

function ReturnsGrid({ returnsList }) {
    const col1Size = 4;
    const col2Size = 4;
    const col3Size = 4;
    const col4Size = 4;
    const col5Size = 4;

    return (
        <Grid
            container
            columns={20}
            sx={{
                "& .MuiBox-root": {
                    boxSizing: "border-box",
                    padding: "3px",
                    borderRight: "1px solid",
                    borderBottom: "1px solid",
                },
                borderTop: "1px solid",
                borderLeft: "1px solid",
            }}
        >
            <Grid xs={col1Size}>
                <Box>Ticker</Box>
            </Grid>
            <Grid xs={col2Size}>
                <Box>Last Price</Box>
            </Grid>
            <Grid xs={col3Size}>
                <Box>Open Price</Box>
            </Grid>
            <Grid xs={col4Size}>
                <Box>Change</Box>
            </Grid>
            <Grid xs={col5Size}>
                <Box>Return</Box>
            </Grid>
            {returnsList.map((ret) => (
                <>
                    <Grid xs={col1Size}>
                        <Box>{ret.ticker}</Box>
                    </Grid>
                    <Grid xs={col2Size}>
                        <Box>{ret.price}</Box>
                    </Grid>
                    <Grid xs={col3Size}>
                        <Box>{ret.openPrice}</Box>
                    </Grid>
                    <Grid xs={col4Size}>
                        <Box>
                            <NumberFormatter
                                value={ret.change}
                                decimalPlaces={2}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={col5Size}>
                        <Box>
                            <NumberFormatter
                                value={ret.return}
                                decimalPlaces={2}
                                makePercent={true}
                            />
                        </Box>
                    </Grid>
                </>
            ))}
        </Grid>
    );
}
