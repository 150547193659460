import React from "react";
import { createBrowserRouter } from "react-router-dom";
import AwardsComponent from "../components/AwardsComponent";
import CardDetail from "../components/CardDetail";
import CardsComponent from "../components/CardsComponent";
import StatsComponent from "../components/StatsComponent";
import TournamentDetail from "../components/TournamentDetail";
import TournamentsComponent from "../components/TournamentsComponent";
import DashboardScreen from "../screens/DashboardScreen";
import LoginScreen from "../screens/LoginScreen";
import StockComponent from "../components/StockComponent";
import { LoginScreenGuardComponent } from "./components/LoginScreenGuardComponent";
import { AppGuardComponent } from "./components/AppGuardComponent";
import TournamentCardsEntryComponent from "../components/tournamentComponents/TournamentCardsEntryComponent";

export const RootPaths = {
    MAIN_ROOT: "/",
    APP_ROOT: "/app",
};

export const UIRoutePaths = {
    AWARDS: `${RootPaths.APP_ROOT}/awards`,
    CARDS: `${RootPaths.APP_ROOT}/cards`,
    CARD_DETAILS: `${RootPaths.APP_ROOT}/cards/:ticker`,
    STOCKS: `${RootPaths.APP_ROOT}/stocks`,
    TOURNAMENTS: `${RootPaths.APP_ROOT}/tournaments`,
    TOURNAMENT_DETAILS: `${RootPaths.APP_ROOT}/tournaments/:id`,
    TOURNAMENT_CARDS_ENTRY: `${RootPaths.APP_ROOT}/tournaments/:id/cards-entry/num-cards/:numCardsNeeded`,
};

export const router = createBrowserRouter([
    {
        path: RootPaths.MAIN_ROOT,
        element: <LoginScreenGuardComponent />,
        children: [{ index: true, element: <LoginScreen /> }],
    },
    {
        path: RootPaths.APP_ROOT,
        element: <AppGuardComponent />,
        children: [
            {
                element: <DashboardScreen />,
                children: [
                    {
                        index: true,
                        element: <StatsComponent />,
                    },
                    {
                        path: UIRoutePaths.STOCKS,
                        element: <StockComponent />,
                    },
                    {
                        path: UIRoutePaths.AWARDS,
                        element: <AwardsComponent />,
                    },
                    {
                        path: UIRoutePaths.CARDS,
                        element: <CardsComponent />,
                    },
                    {
                        path: UIRoutePaths.CARD_DETAILS,
                        element: <CardDetail />,
                    },
                    {
                        path: UIRoutePaths.TOURNAMENTS,
                        element: <TournamentsComponent />,
                    },
                    {
                        path: UIRoutePaths.TOURNAMENT_DETAILS,
                        element: <TournamentDetail />,
                        children: [
                            {
                                path: UIRoutePaths.TOURNAMENT_CARDS_ENTRY,
                                element: <TournamentCardsEntryComponent />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
]);
