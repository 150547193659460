import { useEffect, useRef, useState } from "react";
import { getReturnByTicker } from "../api/returns.service";
import { getLeaderBoard } from "../api/leaderboard.service";

//todo: make this a bit more unit testable so we can pass in mock functions to get updates
export const useReturnsForCardDeck = (tickerList) => {
    const [returnsList, setReturnsList] = useState([]);
    const [updatingReturns, setUpdatingReturns] = useState(false);
    const [error, setError] = useState(false);

    const beginReturnsUpdate = () => setUpdatingReturns(true);

    useEffect(() => {
        if (updatingReturns === true) {
            //todo: maybe prevent requests for returns updates to happen if the tournament hasn't started yet
            //to prevent needless spamming of the server
            Promise.all(tickerList.map((ticker) => getReturnByTicker(ticker)))
                .then((results) => {
                    const errorFound = results.filter((res) =>
                        res.error ? true : false,
                    ).length
                        ? true
                        : false;
                    if (errorFound) {
                        setError(true);
                    } else {
                        setReturnsList(results.map((result) => result.data));
                    }
                })
                .finally(() => setUpdatingReturns(false));
        }
    }, [updatingReturns]);

    return {
        returnsList,
        updating: updatingReturns,
        error,
        beginReturnsUpdate,
    };
};

//todo: pass in a tournament id
export const useLeaderBoard = () => {
    const [leaderBoard, setLeaderBoard] = useState(null);
    const [leaderBoardUpdating, setLeaderBoardUpdating] = useState(false);
    const [error, setError] = useState(false);

    const beginLeaderBoardUpdate = () => setLeaderBoardUpdating(true);

    useEffect(() => {
        if (leaderBoardUpdating === true) {
            getLeaderBoard()
                .then((res) => {
                    if (res.error) {
                        setError(true);
                    } else if (res.data) {
                        setLeaderBoard({
                            ...res.data,
                            results: res.data.results.sort(
                                (rankData1, rankData2) =>
                                    rankData1.rank - rankData2.rank,
                            ),
                        });
                    }
                })
                .finally(() => {
                    setLeaderBoardUpdating(false);
                });
        }
    }, [leaderBoardUpdating]);

    return { leaderBoard, leaderBoardUpdating, error, beginLeaderBoardUpdate };
};

//todo: think of a better label for this hook, as it's silly to set a throttle-time for a player
//todo: maybe clean up these useEffect hooks
export const usePlayerInTournament = (tickerList, throttleTimeMS) => {
    const [throttling, setThrottling] = useState(true);
    const cardDeckState = useReturnsForCardDeck(tickerList);
    const leaderBoardState = useLeaderBoard();
    const throttleTimeOut = useRef(null);

    useEffect(() => {
        if (throttling === true) {
            clearTimeout(throttleTimeOut.current);

            throttleTimeOut.current = setTimeout(() => {
                cardDeckState.beginReturnsUpdate();
                setThrottling(false);
            }, throttleTimeMS);
        }
    }, [throttling]);

    //did we successfully fetch a new list of returns data? update leaderboard
    useEffect(() => {
        //checking for length as the initial state of returnList is empty
        if (cardDeckState.returnsList.length) {
            leaderBoardState.beginLeaderBoardUpdate();
        }
    }, [cardDeckState.returnsList]);

    //did we update the leaderboard and is it not null (null during first mount)?
    //then begin a new cycle of throttling->fetching returns etc
    useEffect(() => {
        if (leaderBoardState.leaderBoard) {
            setThrottling(true);
        }
    }, [leaderBoardState.leaderBoard]);

    useEffect(() => {
        return () => clearTimeout(throttleTimeOut.current);
    }, []);

    return {
        throttling,
        cardDeckState,
        leaderBoardState,
    };
};
