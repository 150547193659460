import { Breadcrumbs, Sheet, Typography } from "@mui/joy";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllUserCards } from "../api/cards.service";

export default function CardsComponent() {
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        getAllUserCards()
            .then((res) => {
                if (res.error) {
                    setError(true);
                } else if (res.data) {
                    setCards(res.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <>
            <Breadcrumbs
                separator={<KeyboardArrowLeft />}
                aria-label="breadcrumbs"
            >
                <Link to="/app">Dashboard</Link>
                <Typography>Cards</Typography>
            </Breadcrumbs>
            {loading === true
                ? "loading"
                : error === true
                  ? "error"
                  : cards.map((c) => {
                        return (
                            <div key={c.ticker}>
                                <Sheet component={Link} to={c.ticker}>
                                    {c.ticker} - {c.id}
                                </Sheet>
                            </div>
                        );
                    })}
        </>
    );
}
