export const formatNumber = (value, numDecimals, includePercent = false) => {
    if (isNaN(value)) {
        throw new Error(
            `cannot format value: invalid value specified, ${value} is not a valid number`,
        );
    }

    let formatted = value.toFixed(numDecimals);

    if (includePercent === true) {
        formatted = parseFloat(formatted) * 100 + "%";
    }

    return formatted;
};
