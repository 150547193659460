//using prod base for now
//todo-refactor for different envs
export const BASE_URL = "https://api.finimmerse.app";

export const BASE_ROUTE_V1 = "/api/v1";

export const FULL_BASE_URL = `${BASE_URL}${BASE_ROUTE_V1}`;

export const APIPaths = {
    CARDS: `${FULL_BASE_URL}/cards`,
    LEADERBOARD: `${FULL_BASE_URL}/leaderboard`,
    LOGIN: `${FULL_BASE_URL}/auth/login`,
    LOGOUT: `${FULL_BASE_URL}/auth/logout`,
    PULSE_CHECK: `${FULL_BASE_URL}/ping`,
    RETURN: `/return`,
    RETURNS: `${FULL_BASE_URL}/returns`,
    SEARCH: `${FULL_BASE_URL}/search`,
    STOCKS: `${FULL_BASE_URL}/stocks`,
    TOURNAMENTS: `${FULL_BASE_URL}/tournaments`,
};
