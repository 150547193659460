import React, { useEffect, useState } from "react";
import Button from "@mui/joy/Button";
import { Box } from "@mui/joy";
import Checkbox from "@mui/joy/Checkbox";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import Typography from "@mui/joy/Typography";
import { useNavigate, useParams } from "react-router";
import { getAllUserCards } from "../../api/cards.service";
import { registerCardsForTournament } from "../../api/tournaments.service";
import { UIRoutePaths } from "../../router/fantasy-stocks-router";

export default function TournamentCardsEntryComponent() {
    const [loadingCards, setLoadingCards] = useState(true);
    const [cards, setCards] = useState([]);
    const [error, setError] = useState(false);
    const { id, numCardsNeeded } = useParams();

    useEffect(() => {
        getAllUserCards()
            .then((res) => {
                if (res.error) {
                    setError(true);
                } else if (res.data) {
                    setCards(res.data);
                }
            })
            .finally(() => setLoadingCards(false));
    }, []);

    return loadingCards ? (
        "loading"
    ) : error ? (
        "error"
    ) : (
        <CardSelectionComponent
            id={id}
            cards={cards}
            numCardsNeeded={numCardsNeeded}
        />
    );
}

function CardSelectionComponent({ id, cards, numCardsNeeded }) {
    const initialSubmissionState = {
        submittingCards: false,
        submissionSucceeds: false,
        submissionFailureStatus: {
            failed: false,
            statusCode: null,
            errorMessage: "",
        },
    };

    const [submittingCardState, setSubmittingCardState] = useState(
        initialSubmissionState,
    );
    const [cardsSelectedById, setCardsSelectedById] = useState({});
    const navigate = useNavigate();

    const cardSelectionCount = Object.keys(cardsSelectedById).length;
    const userTriedToReRegister =
        submittingCardState.submissionFailureStatus.statusCode === 409;

    const handleCardSelection = (card) => {
        if (!cardsSelectedById[card.cardId]) {
            setCardsSelectedById({
                ...cardsSelectedById,
                [card.cardId]: card.ticker,
            });
        } else {
            delete cardsSelectedById[card.cardId];
            setCardsSelectedById({ ...cardsSelectedById });
        }
    };

    useEffect(() => {
        if (submittingCardState.submittingCards === true) {
            const cardsFromState = Object.keys(cardsSelectedById).map(
                (cardId) => cardsSelectedById[cardId],
            );
            registerCardsForTournament(id, cardsFromState).then((resp) => {
                if (resp.status === 201) {
                    setSubmittingCardState({
                        ...initialSubmissionState,
                        submissionSucceeds: true,
                    });
                } else {
                    let errorMessage = "";

                    switch (resp.status) {
                        case 409:
                            errorMessage =
                                "You've already registered for this tournament";
                            break;
                        default:
                            errorMessage = "error";
                            break;
                    }

                    setSubmittingCardState({
                        ...initialSubmissionState,
                        submissionFailureStatus: {
                            failed: true,
                            statusCode: resp.status,
                            errorMessage,
                        },
                    });
                }
            });
        }
    }, [submittingCardState.submittingCards]);

    useEffect(() => {
        //we should still redirect if the user tried to re-register as they have no business
        //using this tool and being here
        const mustRedirect =
            submittingCardState.submissionSucceeds === true ||
            userTriedToReRegister === true;

        if (mustRedirect) {
            setTimeout(() => {
                //give the user a little time to read whatever message is displayed about their
                //submission status
                navigate(`${UIRoutePaths.TOURNAMENTS}/${id}`);
            }, 1000);
        }
    }, [submittingCardState.submissionSucceeds, userTriedToReRegister]);

    return (
        <>
            <List disabled={userTriedToReRegister}>
                {cards.map((card) => {
                    //disable the checkbox if the user has already selected the required
                    //number AND if it's unselected to prevent the user from checking off
                    //more than the req amt
                    const shouldDisable =
                        parseInt(cardSelectionCount) ===
                            parseInt(numCardsNeeded) &&
                        !cardsSelectedById[card.cardId];
                    return (
                        <ListItem key={card}>
                            <Checkbox
                                disabled={shouldDisable}
                                checked={
                                    cardsSelectedById[card.cardId]
                                        ? true
                                        : false
                                }
                                onChange={() => {
                                    handleCardSelection(card);
                                }}
                                label={<Typography>{card.ticker}</Typography>}
                                sx={{
                                    cursor:
                                        shouldDisable === true
                                            ? "not-allowed"
                                            : "pointer",
                                    "& .MuiSvgIcon-root": {
                                        color:
                                            shouldDisable === true
                                                ? "grey"
                                                : "white",
                                    },
                                }}
                            />
                        </ListItem>
                    );
                })}
            </List>
            <Typography>Cards Checked : {cardSelectionCount}</Typography>
            {parseInt(numCardsNeeded) === parseInt(cardSelectionCount) && (
                <>
                    <Button
                        variant="plain"
                        color="neutral"
                        component="a"
                        size="sm"
                        sx={{
                            flexDirection: "column",
                            "--Button-gap": 0,
                            backgroundColor: "red",
                        }}
                        onClick={() =>
                            setSubmittingCardState({
                                ...initialSubmissionState,
                                submittingCards: true,
                            })
                        }
                        disabled={
                            parseInt(cardSelectionCount) !==
                            parseInt(numCardsNeeded)
                        }
                    >
                        <Typography>Lock Cards & Play</Typography>
                    </Button>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "2em",
                        }}
                    >
                        <Typography>
                            {submittingCardState.submittingCards === true &&
                                `submitting cards`}
                            {submittingCardState.submissionSucceeds === true &&
                                `submission succeeds`}
                            {submittingCardState.submissionFailureStatus
                                .failed === true &&
                                submittingCardState.submissionFailureStatus
                                    .errorMessage}
                        </Typography>
                    </Box>
                </>
            )}
        </>
    );
}
